import lazyLoading from './lazyLoading';

export default {
    routes: [

        // Main App routes
        {
            path: "/",
            component: lazyLoading('layout/AppLayout'),
            children: [
                {
                    path: '',
                    name: 'home',
                    component: lazyLoading('app/Home')
                },
                {
                    path: "/material-:name",
                    name: "material-:name",
                    component: lazyLoading('app/Materials')
                },
                {
                    path: "/material/display/:id",
                    name: "material-display",
                    component: lazyLoading('app/MaterialDisplay')
                },
                {
                    path: "/photos",
                    name: "photos",
                    component: lazyLoading('app/Photos')
                },
                {
                    path: "/photos/album/:id",
                    name: "gallery",
                    component: lazyLoading('app/Gallery')
                },
                /*{
                    path: "/orchestras",
                    name: "orchestras",
                    component: lazyLoading('app/Orchestras')
                },*/
                {
                    path: "/links",
                    name: "links",
                    component: lazyLoading('app/Links')
                },
                {
                    path: "/programs",
                    name: "programs",
                    component: lazyLoading('app/Programs')
                },
    
                // Parzdnik Trub routes
                {
                    path: "/prazdniktrub",
                    name: "prazdniktrub",
                    component: lazyLoading('app/Prazdniktrub')
                },
                {
                    path: "/prazdniktrub/2005-mp3",
                    name: "trub2005_mp3",
                    component: lazyLoading('app/Trub2005_mp3')
                },
                {
                    path: "/prazdniktrub/2005",
                    name: "trub2005",
                    component: lazyLoading('app/Trub2005')
                },
                {
                    path: "/prazdniktrub/2006",
                    name: "trub2006",
                    component: lazyLoading('app/Trub2006')
                },
                {
                    path: "/prazdniktrub/2007",
                    name: "trub2007",
                    component: lazyLoading('app/Trub2007')
                },
                {
                    path: "/prazdniktrub/2008",
                    name: "trub2008",
                    component: lazyLoading('app/Trub2008')
                },
                {
                    path: "/prazdniktrub/2011",
                    name: "trub2011",
                    component: lazyLoading('app/Trub2011')
                }
            ]
        },
    
        // Old Redirects
        {
            path: "/material/orchestra",
            redirect: "/material-orchestra"
        },
        {
            path: "/material/choir",
            redirect: "/material-choir"
        },
        {
            path: "/material/learning",
            redirect: "/material-learning"
        },
        {
            path: "/sheetmusic",
            redirect: "/material-orchestra"
        },
        {
            path: "/sheetMusic",
            redirect: "/material-orchestra"
        },
        {
            path: "/sheetmusic.php",
            redirect: "/material-orchestra"
        },
        {
            path: "/sheetmusic.html",
            redirect: "/material-orchestra"
        },
        {
            path: "/sheetMusic.php",
            redirect: "/material-orchestra"
        },
        {
            path: "/photos.php",
            redirect: "/photos"
        },
        {
            path: "/orchestras.php",
            redirect: "/orchestras"
        },
        {
            path: "/orchestras",
            redirect: "/"
        },
        {
            path: "/links.php",
            redirect: "/links"
        },
        {
            path: "/other/programs.php",
            redirect: "/programs"
        },
        {
            path: "/index.html",
            redirect: "/"
        },
        {
            path: "/index",
            redirect: "/"
        },

        // Empty redirects
        {
            path: "/material/display",
            redirect: "/"
        },
        {
            path: "/material",
            redirect: "/"
        },
        {
            path: "/photos/album",
            redirect: "/"
        },
        {
            path: "/contact",
            redirect: "/"
        },
        {
            path: "/other",
            redirect: "/"
        }
    ]
};