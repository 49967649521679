<template>
    <div id="app">
        <main>
            <router-view />
        </main>
    </div>
</template>
<script>


export default {
};
</script>
