import Vue from "vue";
import Router from "vue-router";
import app from "./app";

Vue.use(Router);

export default new Router({
    linkExactActiveClass: "active",
    mode: "history",
    routes: app.routes,
    scrollBehavior: to => {
        if (to.hash) {
            return { selector: to.hash };
        } else {
            return { x: 0, y: 0 };
        }
    }
});
