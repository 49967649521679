import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import "./assets/scss/wo.scss";
import BootstrapVue from 'bootstrap-vue';
import VueGtag from "vue-gtag";
import { library } from '@fortawesome/fontawesome-svg-core';
import {
    faTrash,
    faPaperPlane,
    faPhoneAlt,
    faEnvelope,
    faChartBar,
    faFileMedical,
    faTasks,
    faFolderPlus,
    faFileAudio,
    faImages,
    faMobileAlt
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add(faTrash);
library.add(faPaperPlane);
library.add(faPhoneAlt);
library.add(faEnvelope);
library.add(faChartBar);
library.add(faFileMedical);
library.add(faTasks);
library.add(faFolderPlus);
library.add(faFileAudio);
library.add(faImages);
library.add(faMobileAlt);
 
Vue.component('FontAwesomeIcon', FontAwesomeIcon);

Vue.config.productionTip = false;
Vue.use(BootstrapVue);

const env = process.env.NODE_ENV === 'production' ? 'prod' : 'dev';

Vue.prototype.$apiUrl = "https://8awk4sn303.execute-api.us-west-2.amazonaws.com/" + env;

let gtag_id = '';

if (env === 'prod') {
    gtag_id = 'UA-32103677-1';
} else {
    gtag_id = 'UA-32103677-2';
}

Vue.use(VueGtag, {
    config: { 
        id: gtag_id
    }
}, router);

new Vue({
    router,
    render: h => h(App)
}).$mount("#app");
